import styled, { css } from "styled-components";


export const Wrapper = styled.div`
  text-align: center;
  padding: 42px 10px 8px;

  ${props => props.paper && css`
    background: ${({ theme }) => theme.background.paper};
    padding-top: 16px;
    padding-bottom: 16px;
  `}
  

  @media ${({ theme }) => theme.device.mobile}{
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Youtube = styled.iframe`
  width: 960px;
  height: 540px;
  margin: 0 auto 24px;
  border: 0;

  @media ${({theme}) => theme.device.tablet}{
    width: 740px;
    height: 416px;
  }

  @media ${({theme}) => theme.device.mobile}{
    width: 340px;
    height: 191px;
  }

  @media only screen and (max-width: 350px){
    width: 300px;
    height: 169px;    
  }
`;
export const Container = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.size.container}px;
  margin: 0 auto;
  text-align: left;
  display: block;

  ${props => props.center && css`
    text-align: center;
  `}

  ${props => props.margin && css`
    margin: 32px auto;
  `}

  @media ${({ theme }) => theme.device.phone}{
    text-align: center;
  }
`;

export const HeroTitleSub = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${({ theme }) => theme.text.secondary};
  margin-bottom: 24px;

`

export const DescriptionMain = styled.p`
  text-align: center;
  margin-bottom: 32px;
`;


