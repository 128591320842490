import styled, { css } from "styled-components";
import {
  getAngleBySize,
  getAngleByCommonColor,
  getAngleByHierarchyColor,
  getAngleByStyleColor,
  COMMON_COLORS,
  HIEARARCHY_COLORS,
  STYLE_COLORS,
} from '../atom.helper';


const Wrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.background.paper};
  padding: 16px 8px;
  margin-left: 8px;
  margin-right: 8px;

  ${getAngleBySize({ angle: '8px' })}

  ${props => props.size === "large" && css`
    padding: 12px 0;
    margin-left: 12px;
    margin-right: 12px;
    ${getAngleBySize({ angle: '12px' })}

    ${props => props.padding && css`
      padding: 24px 12px;
    `};
  `}

  ${props => props.size === "normal" && css`
    padding: 8px 0;
    margin-left: 8px;
    margin-right: 8px;
    ${getAngleBySize({ angle: '8px' })}

    ${props => props.padding && css`
      padding: 16px 8px;
    `};
  `}

  ${props => props.size === "small" && css`
    padding: 4px 0;
    margin-left: 4px;
    margin-right: 4px;
    ${getAngleBySize({ angle: '4px' })}

    ${props => props.padding && css`
      padding: 8px 4px;
    `};
  `}

  ${props => props.color
    && HIEARARCHY_COLORS.includes(props.color)
    && getAngleByHierarchyColor(props)
  };

  ${props => props.color
    && COMMON_COLORS.includes(props.color)
    && getAngleByCommonColor(props)
  };

  ${props => props.color
    && STYLE_COLORS.includes(props.color)
    && getAngleByStyleColor(props)
  };

  ${props => props.shadow && css`
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);

    @media ${({ theme }) => theme.device.mobile}{
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }
  `}
`;


export default Wrapper;