import React from 'react';
import { Button } from "../../../"
import {
  Wrapper,
} from '../..';
import styled, { css } from 'styled-components';


const Container = styled.div`

`;

const Title = styled.h3`
  font-family: "Quantico", sans-serif;
  font-size: 16px;
  line-height: 19px;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.primary.main};
  text-align: left;
`;


const Description = styled.div`
  font-size: 15px;
  line-height: 19px;
  color:  ${({ theme }) => theme.text.secondary};
`;

const Link = styled.a`

`;

const Grid = styled.div`
  ${props => props.container && css`
    display: grid;
    grid-template-areas:
    "image"
    "title"
    "description"
    "call2action";
  `}

  ${props => props.image && css`
    grid-area: image;
    /* min-height: 240px; */
    margin: 4px;
  `}

  ${props => props.text && css`
    grid-area: title;
    margin: 8px 8px 4px;
  `}

  ${props => props.description && css`
    grid-area: description;
    margin: 4px 8px 4px;
  `}
  ${props => props.call2action && css`
    grid-area: call2action;
    margin: 2px 8px 8px 4px;
  `}
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`


const CardThumbnails = ({ title, description, image, url, call2action = null }) => (
  <Container>
    <Wrapper
      size="small"
      color="background-paper"
    >
      <Grid container>
        <Grid image>
          <Image
            src={image}
            alt={title}
          />
        </Grid>
        <Grid text>
          {url && (
            <Link
              href={url}
              title={title}
              target="_blank"
            >
              <Title>
                {title}
              </Title>
            </Link>)}
          {!url && <Title>{title}</Title>}
        </Grid>
        <Grid description>
          {description && (
            <Description>
              {description}
            </Description>
          )}
        </Grid>
        {call2action && (
          <Grid call2action>
            <Button
              type="link"
              size="normal"
              color="secondary"
              to={url}
            >
              {call2action}
            </Button>

          </Grid>
        )}
      </Grid>
    </Wrapper>
  </Container>
);

export default CardThumbnails;