import React from 'react';
import {
  Wrapper,
} from '../..';
import styled, { css } from 'styled-components';


const Container = styled.div`
  text-align: left;
`;

const Title = styled.h2`
  font-family: "Quantico", sans-serif;
  font-size: 18px;
  line-height: 21px;
  margin-left: 14px;
  color: ${({ theme }) => theme.primary.main};
  text-align: left;
`;

const Description = styled.div`
  margin: 8px 16px;
  p{
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    padding: 0;
  }
`;

const Image = styled.img`
  max-height: 60px;
  width: auto;
  margin-top: 10px;
  margin-right: 10px;
  float: right;


  @media ${({ theme }) => theme.device.phone}{
    float: none;
    margin: 20px;
  }
`;

const CardInfo = ({ title, image, description }) => (
  <Container>
    <Wrapper
      size="small"
      color="background-paper"
    >
      {image && (
        <Image
          src={image}
          alt={title}
        />
      )}
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Wrapper>
  </Container>
)

export default CardInfo;