import React from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
  Button,
} from '../ui-components';
import {
  DescriptionMain,
} from '../components/mission-statement/mission-statement.styled';
import { countries } from "../utils";
import { ItemGrid, CardInfo } from "../ui-components/scss";
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";


const Grid = styled.div`
  ${props => props.container && css`
    position: relative;
    padding-left: 320px;
    overflow: hidden;

    @media ${({ theme }) => theme.device.tablet}{
      margin: auto;
      padding-left: 0;
    }

    @media ${({ theme }) => theme.device.mobile}{
      margin: auto;
      padding: 0 20px;
    }

    @media ${({ theme }) => theme.device.phone}{
      margin: auto;
      padding: 0 20px;
    }
  `}

  ${props => props.map && css`
    max-width: 620px;
    position: absolute;
    left: -40px;
    top: 70px;

    @media ${({ theme }) => theme.device.tablet}{
      position: relative;
      top: auto;
      left: auto;
      margin: auto;
    }
    @media ${({ theme }) => theme.device.mobile}{
      position: relative;
      top: auto;
      left: auto;
      margin: auto;
    }
    @media ${({ theme }) => theme.device.phone}{
      position: relative;
      top: auto;
      left: auto;
      margin: auto;
    }
  `}

  ${props => props.dealerList && css`
    position: relative;
    z-index: 1;
    max-width: 520px;
    margin-left: auto;

    @media ${({ theme }) => theme.device.tablet}{
      top: -140px;
      margin: auto;
    }
    @media ${({ theme }) => theme.device.mobile}{
      top: -80px;
      margin: auto;
    }
    @media ${({ theme }) => theme.device.phone}{
      top: 0;;
      margin: auto;
    }

  `}
`

const DealershipMap = styled(GatsbyImage)``;

const getDescription = (data) => (
  <>
    <p><strong>Email:</strong> <a href={`mailto:${data.email}`}>{data.email}</a></p>
    <p><strong>Website:</strong> <a href={data.website} target="_blank">{data.website}</a></p>
    <br />
    <p><strong>Address:</strong><br />
      {data.address.address},<br />
      {/* {data.address.address}, {data.address.address_2}<br /> */}
      {data.address.city}, {data.address.postal_code}<br />
      {data.country}
    </p>
  </>
)

const DealersPage = ({ data, location }) => {
  const content = data.content.frontmatter || {};
  const html = data.content.html;
  const dealershipList = data.dealershipList.nodes[0].data || [];
  const dealershipMap = data.dealershipMap;

  const dealershipCountries = dealershipList.map(data => {
    const country = countries.find(el => el.code === data.region);
    const dealers = dealershipList
      .map(el => ({ ...el, country: country.name }))
      .filter(el => el.region == data.region)
      .sort((a, b) => a.order - b.order);

    return (
      {
        region: data.region,
        country: country.name,
        dealers: dealers,
      })
  }).sort((a, b) => a.name - b.name)

  return (
    <>
      <SEO
        canonical="/dealers/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>

          <PageTitle>{content.title}</PageTitle>
          <DescriptionMain dangerouslySetInnerHTML={{ __html: content.description }} />

          <br />

          <Grid container>
            <Grid map>
              <DealershipMap
                image={dealershipMap.childImageSharp.gatsbyImageData}
                alt="BeTRITON Dealerships"
              />
            </Grid>
            <Grid dealerList>
              {dealershipCountries.map((data, index) => (
                <ItemGrid
                  key={index}
                  item="info"
                  title={data.country}
                >
                  {data.dealers.map((data, index) =>
                    <CardInfo
                      key={index}
                      title={data.title}
                      description={getDescription(data)}
                      image={data.image?.original || null}
                      url={data.website}
                      call2action="Rent"
                    />
                  )}
                </ItemGrid>
              ))}
              <br />
    
              <div style={{ textAlign: "center" }}>
                <Button
                  type="anchor"
                  target="_self"
                  to="https://my.betriton.com/community/dealership"
                  color="secondary"
                  size="large"
                >Become a dealer</Button>
              </div>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>

        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>

    </>
  );
}
export default DealersPage;


export const pageQuery = graphql`
 query {
   site {
     siteMetadata {
       title
     }
   }
   content: markdownRemark(fileAbsolutePath: {regex: "/content/pages/dealers.md/"}) {
     frontmatter {
       title
     }
     html
   }
   dealershipMap: file(relativePath: {eq: "dealership-map.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 800,
        quality: 100
        placeholder: BLURRED
        breakpoints: [800, 360]
      )
    }
  }
   dealershipList: allRestApiV1UserCommunityRoleDealershipList {
    nodes {
      data {
        address {
          address
          address_2
          city
          postal_code
          country
          state
        }
        email
        title
        region
        website
        image {
          original
        }
      }
    }
  }
 }
 `;

