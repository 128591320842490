import React from 'react';
import {
  CardThumbnails,
  CardInfo,
  Wrapper,
} from '../..';
import styled, { css } from 'styled-components';


export const Container = styled.div`
`;

export const Title = styled.h2`
  font-family: "Quantico", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 21px;
  margin-left: 14px;
  color: ${({ theme }) => theme.text.secondary};
  text-align: left;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;


  ${props => props.type === "thumbnail" && css`
    margin-bottom: -4px;

    & > * {
      flex-grow: 1;
      max-width: calc(33.3% - 4px);
      width: calc(33.3% - 4px);
      margin-right: 4px;

      margin-bottom: 4px;

      &:nth-child(3n){
        margin-right: 0;
        max-width: calc(33.4%);
        width: calc(33.4%);
      }
    }
  `}

  ${props => props.type === "info" && css`
    margin-bottom: -4px;
    & > * {
      flex-grow: 1;
      width: 100%;
      margin-bottom: 4px;
    }
  `}
`;



const Item = ({ type, ...data }) => {
  switch (type) {
    default:
    case "info":
      return <CardInfo {...data} />
    case "thumbnail":
      return <CardThumbnails {...data} />
  }
}


const ItemGrid = ({ title, items, children, item = "info", colls = 3 }) => {
  
  // const itemCount = items.length || 0

  return (
    <Container>
      {title && <Title>{title}</Title>}
      <Wrapper
        color="background-dark-2"
        size="small"
      >
        <Grid type={item}>
          {children}
        </Grid>
      </Wrapper>
    </Container>
  )
}

export default ItemGrid;